/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        function fadeHeaderOnScroll() {

          $(window).scroll(function() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            var $header = $('.js-site-header');

            if (scrollTop > 400) {
            
              $header.addClass('is-sticky');
              $('body').addClass('is-header-hided');
            }
            else {
                  
              $header.removeClass('is-sticky');
              $('body').removeClass('is-header-hided');
            }
          }).scroll();

        }
        fadeHeaderOnScroll();


        (function offCanvasMenu() {

            var tlOffcanvas = new TimelineMax({
                paused: !0
            }),
            menuContainer = $('.js-main-nav'),
            menuBg = $('.js-main-nav-bg'),
            menuTexture = $('.js-main-nav-texture'),
            menuCanvas = $('.js-main-nav-canvas'),
            menuLogo = $('.js-main-nav-logo'),
            menuLinks = $('.js-main-nav-links'),
            menuLink = $('.js-main-nav-link');

            tlOffcanvas.fromTo(menuContainer, 0.1, {
                display:"none", 
                opacity:0,                
                zIndex: 0
            }, {
                display:"block", 
                opacity:1,                
                zIndex: 8
            }).fromTo(menuBg, .2, {
                display:"none", 
                opacity:0
            }, {
                display:"block", 
                opacity:1,
                ease: Power2.easeInOut
            }).staggerFromTo(menuLink, .3, {
                  y: 10,
                  opacity: 0
              }, {
                  y: 0,
                  opacity: 1,
                  ease: Power2.easeInOut
              }, "+=.1").fromTo(menuLogo, 0.4, {
                opacity: 0
            }, {
               opacity: 1,
                ease: Power2.easeInOut
            }, 0);            
            

            // Toggle Nav
            $('.js-toggle-nav:not(.-inited)').on('click', function () {
                
                if ($('body').hasClass('is-nav-active')) {
                    $('body').toggleClass('is-nav-active');
                    tlOffcanvas.reverse();
                    $('.js-main-nav').toggleClass('is-leaving');
                    $('.js-main-nav').removeClass('is-active');
                } else {
                    $('body').toggleClass('is-nav-active');
                    $('.js-main-nav').toggleClass('is-leaving');
                    tlOffcanvas.play();
                    $('.js-main-nav').addClass('is-active');                
                }

            }).addClass("-inited");


        })();


        (function scrollToContent() {

          var $button = $("#downArrow");

          $button.on("click", function(){
              var $this = $(this),
                  href = $this.attr("href"),
                  topY = $(href).offset().top;

              TweenMax.to($(window), 0.8, {
                  scrollTo:{
                      y: topY+10,
                      autoKill: true
                  },
                  ease:Power3.easeInOut
                  
               });

            return false;

          });

        })();      


        (function accordionMenu() {

          // Accordion
          $('.js-accordion:not(.-inited)').click(function () {
              setTimeout(function () {
                  $(document).trigger('SmoothScroll.rebuild');
              }, 400);

              if ($(this).hasClass('is-open')) {
                  $(this).removeClass('is-open').next().slideUp(300);
              } else {
                  if ($('.js-accordion.is-open').length) {
                      $('.js-accordion.is-open').removeClass('is-open').next().slideUp(300);
                  }
                  $(this).addClass('is-open').next().slideDown(300);
                  var _this = $(this); // scope baby
                  setTimeout(function () {
                      $('html, body').animate({
                          scrollTop: $(_this).offset().top - 160
                      }, 500);
                  }, 300);
              }
              return false;
          }).addClass("-inited"); 

        })();

        (function initSlick() {

          $(document).ready(function() {
            var $slider = $('.js-slider-irregular');
            var $pagingNav = $('.js-slider-paging-wrap');
            var $status = $('.js-slider-paging');
            var $slickElement = $('.js-slider-irregular');
            var $arrows = $('.js-slider-arrows');
            var $progressBar = $('.js-slider-progress');
            var $progressBarLabel = $( '.js-slider-progress-label' );

            $slider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
              //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
              var i = (currentSlide ? currentSlide : 0) + 1;
              $status.html('<span id="home-pagination__number__current" class="c-slider__page__num__current">' + i + '</span>' +  '<span id="home-pagination__number__separator" class="c-slider__page__num__separator">/</span>' + '<span id="home-pagination__number__total" class="c-slider__page__num__total">' + slick.slideCount + '</span>');
            });

            // On before slide change
            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){

              $pagingNav.toggleClass('is-changing');

              var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

              $progressBar
                .css('background-size', calc + '% 100%')
                .attr('aria-valuenow', calc );
              
              //$progressBarLabel.text( calc + '% completed' );

            });   

            $slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
              $pagingNav.toggleClass('is-changing');
            });    



            // $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {   
            //   var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;
              
            //   $progressBar
            //     .css('background-size', calc + '% 100%')
            //     .attr('aria-valuenow', calc );
              
            //   $progressBarLabel.text( calc + '% completed' );
            // });




            $slider.slick({
              infinite: true,
              centerMode: true,
              centerPadding: '120px',            
              slidesToShow: 2,
              slidesToScroll:1,
              arrows:true,
              prevArrow: $arrows.find('.js-arrow-left'), 
              nextArrow: $arrows.find('.js-arrow-right'),              
              varialeWidth:true,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px'
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                  }
                }
              ]

            });

          });

        })();
        

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(window).on('load', function() {
          $('body').toggleClass('is-home');
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
